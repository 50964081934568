<template>
    <div class="edit-profile-detail">
        <StackRouterHeaderBar left-button="close" title="가족관계" :showTitle="true" />
        <div class="title" v-html="`${$translate(familyType.name.toUpperCase())}`" />
        <div class="section" v-if="familyType.id === 2">
            <div class="section-title required" v-html="`형제 관계`" />
            <TextareaWithX v-model="brotherhoodStatus" :placeholder="`첫째 누나,셋째 남동생 등`" :isInputMode="true" />
        </div>
        <div class="section" v-else>
            <div class="section-title required" v-html="`생사여부`" />
            <div class="f-14">해당 정보는 매칭 참고용으로 매니저에게만 공유됩니다.</div>
            <div class="button-container m-t-8">
                <div
                    class="btn-normal"
                    :class="{ selected: liveStatus === btn.id }"
                    v-for="btn in aliveButtons"
                    :key="`btn-${btn.id}`"
                    v-html="btn.name"
                    @click="onClickBtn(btn.id)"
                />
            </div>
        </div>
        <div class="section">
            <div class="section-title required" v-html="`최종학력`" />
            <SelectInput
                :alreadySelected="(selectedEduStatus || {}).id"
                :options="educationStatus"
                @selected="onSelectEduStatus"
                :scrollFix="true"
                v-if="showSelectInput"
            />
            <div class="section-title required">정보공개 방법</div>
            <div class="selector-container">
                <div
                    class="select"
                    v-for="select in selectors"
                    :key="`select-${select.id}`"
                    @click="show_education_status = select.id"
                >
                    <img
                        class="m-r-4"
                        :src="
                            require(`@/assets/images/icons/${
                                show_education_status === select.id ? 'radio_on' : 'radio_off'
                            }.png`)
                        "
                        width="24px"
                        height="24px"
                    />
                    <span v-html="select.key" />
                </div>
            </div>
        </div>
        <div class="section" v-if="selectedEduStatus && selectedEduStatus.id > 2">
            <div class="section-title" v-html="`출신 대학교`" />
            <div class="search-box-wrapper" @click="onClickUniv">
                <div class="search-box" v-if="university" v-html="university.name" />
                <div class="search-box" v-else>
                    <i class="material-icons">search</i>
                    <span v-html="$translate(`PLACEHOLDER_SEARCH_EDUCATION_UNIVERSITY`)" />
                </div>
            </div>
        </div>
        <div class="section">
            <div class="section-title required" v-html="`직업`" />
            <TextareaWithX v-model="jobName" placeholder="임원, 주부 등" :isInputMode="true" />
            <div class="section-title required">정보공개 방법</div>
            <div class="selector-container">
                <div
                    class="select"
                    v-for="select in selectors"
                    :key="`select-${select.id}`"
                    @click="show_job_name = select.id"
                >
                    <img
                        class="m-r-4"
                        :src="
                            require(`@/assets/images/icons/${
                                show_job_name === select.id ? 'radio_on' : 'radio_off'
                            }.png`)
                        "
                        width="24px"
                        height="24px"
                    />
                    <span v-html="select.key" />
                </div>
            </div>
        </div>
        <!-- <div class="section">
        </div> -->
        <div class="section">
            <div class="section-title" v-html="`직장명`" />
            <TextareaWithX placeholder="직장명 입력" v-model="companyName" :isInputMode="true" />
            <div class="section-title required">정보공개 방법</div>
            <div class="selector-container">
                <div
                    class="select"
                    v-for="select in selectors"
                    :key="`select-${select.id}`"
                    @click="show_company_name = select.id"
                >
                    <img
                        class="m-r-4"
                        :src="
                            require(`@/assets/images/icons/${
                                show_company_name === select.id ? 'radio_on' : 'radio_off'
                            }.png`)
                        "
                        width="24px"
                        height="24px"
                    />
                    <span v-html="select.key" />
                </div>
            </div>
        </div>
        <div class="section current" @click="jobStatus = !jobStatus">
            <Toggler v-model="jobStatus" class="m-r-10" />

            <div class="section-title">현재 재직중</div>
        </div>
        <BottomButton @click="submit" :disabled="disabled" :label="$translate('SAVE')" />
    </div>
</template>

<script>
import SelectInput from '@/components/app/SelectInput'

export default {
    name: 'EditProfileDetailFamily',
    props: {
        familyType: Object,
        edit: Object,
    },
    components: {
        SelectInput,
    },
    computed: {
        educationStatus() {
            return Object.keys(this.$profile.educationStatus).map(key => this.$profile.educationStatus[key])
        },
        selectors() {
            return [
                {
                    id: 1,
                    key: `전체 공개`,
                },
                {
                    id: 0,
                    key: `비공개(매니저에게만 공개)`,
                },
            ]
        },
        disabled() {
            if (
                !(
                    this.show_company_name !== null &&
                    this.selectedEduStatus &&
                    this.jobName &&
                    this.show_education_status !== null
                )
            )
                return true

            if (this.familyType.id === 2) {
                return !this.brotherhoodStatus
            }
            return this.liveStatus === null
        },
        privacySetting() {
            return {
                // show_company_name: this.selectedShowMethod,
                // show_education_status: 1,
                // show_job_name: 1,
                show_job_status: 1,
                show_live_status: 0,
                show_university_name: 1,
            }
        },
    },
    data: () => ({
        showSelectInput: false,
        // option
        jobStatus: true,
        selectedShowMethod: null,
        university: null,

        // required
        brotherhoodStatus: null,
        companyName: null,
        jobName: null,
        selectedEduStatus: null,
        liveStatus: null,
        // 정보공개방법
        show_company_name: null,
        show_education_status: null,
        show_job_name: null,
        aliveButtons: [
            {
                id: 1,
                name: '살아계심',
                selected: false,
            },
            {
                id: 0,
                name: '작고',
                selected: false,
            },
        ],
    }),
    methods: {
        onClickSelector(id) {
            this.selectedShowMethod = id
        },
        onClickBtn(id) {
            this.aliveButtons = this.aliveButtons.map(btn => ({
                ...btn,
                selected: btn.id === id,
            }))
            this.liveStatus = id
        },
        onSelectEduStatus(item) {
            this.selectedEduStatus = item
        },
        async onClickUniv() {
            this.university = await this.$modal.custom({
                component: 'ModalProfileItemSelector',
                options: {
                    model: 'university',
                },
            })
        },
        submit() {
            try {
                let { family } = this.$store.getters.profile

                const obj = {
                    ...this.privacySetting,
                    family_type: this.familyType.id,
                    education_status: this.selectedEduStatus.id,
                    job_name: this.jobName,
                    company_name: this.companyName,
                    job_status: this.jobStatus ? 1 : 0,

                    show_company_name: this.show_company_name,
                    show_education_status: this.show_education_status,
                    show_job_name: this.show_job_name,
                }

                if (this.familyType.id === 2) {
                    obj.brotherhood_status = this.brotherhoodStatus
                } else {
                    obj.live_status = this.liveStatus
                }

                if ((this.selectedEduStatus || {}).id >= 2 && this.university) {
                    obj.university_id = this.university.id
                    obj.university_name = this.university.name
                }
                if (this.edit) {
                    // family = family.filter(item => item.id !== this.edit.id)
                    const idx = family.findIndex(item => item.id === this.edit.id)
                    obj.id = this.edit.id
                    family.splice(idx, 1, obj)
                } else {
                    family = [...family, obj]
                }

                this.$set(this.$store.getters.profile, 'family', family)
                this.$stackRouter.pop()
            } catch (e) {
                console.log(e)
            }
        },
        init() {
            if (this.edit) {
                this.jobName = this.edit.job_name
                this.companyName = this.edit.company_name
                this.brotherhoodStatus = this.edit.brotherhood_status || null
                this.liveStatus = this.edit.live_status
                this.selectedEduStatus = this.$profile.educationStatus[this.edit.education_status]
                this.jobStatus = this.edit.job_status

                this.show_company_name = this.edit.show_company_name
                this.show_education_status = this.edit.show_education_status
                this.show_job_name = this.edit.show_job_name

                if (this.edit.university_id) {
                    this.university = {
                        id: this.edit.university_id,
                        name: this.edit.university_name,
                    }
                }
                // this.selectedShowMethod = this.edit.show_company_name
            }

            this.showSelectInput = true
        },
    },
    mounted() {
        try {
            this.init()
        } catch (e) {
            console.log(e)
        }
    },
}
</script>

<style scoped></style>
