var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"edit-profile-detail"},[_c('StackRouterHeaderBar',{attrs:{"left-button":"close","title":"가족관계","showTitle":true}}),_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(`${_vm.$translate(_vm.familyType.name.toUpperCase())}`)}}),(_vm.familyType.id === 2)?_c('div',{staticClass:"section"},[_c('div',{staticClass:"section-title required",domProps:{"innerHTML":_vm._s(`형제 관계`)}}),_c('TextareaWithX',{attrs:{"placeholder":`첫째 누나,셋째 남동생 등`,"isInputMode":true},model:{value:(_vm.brotherhoodStatus),callback:function ($$v) {_vm.brotherhoodStatus=$$v},expression:"brotherhoodStatus"}})],1):_c('div',{staticClass:"section"},[_c('div',{staticClass:"section-title required",domProps:{"innerHTML":_vm._s(`생사여부`)}}),_c('div',{staticClass:"f-14"},[_vm._v("해당 정보는 매칭 참고용으로 매니저에게만 공유됩니다.")]),_c('div',{staticClass:"button-container m-t-8"},_vm._l((_vm.aliveButtons),function(btn){return _c('div',{key:`btn-${btn.id}`,staticClass:"btn-normal",class:{ selected: _vm.liveStatus === btn.id },domProps:{"innerHTML":_vm._s(btn.name)},on:{"click":function($event){return _vm.onClickBtn(btn.id)}}})}),0)]),_c('div',{staticClass:"section"},[_c('div',{staticClass:"section-title required",domProps:{"innerHTML":_vm._s(`최종학력`)}}),(_vm.showSelectInput)?_c('SelectInput',{attrs:{"alreadySelected":(_vm.selectedEduStatus || {}).id,"options":_vm.educationStatus,"scrollFix":true},on:{"selected":_vm.onSelectEduStatus}}):_vm._e(),_c('div',{staticClass:"section-title required"},[_vm._v("정보공개 방법")]),_c('div',{staticClass:"selector-container"},_vm._l((_vm.selectors),function(select){return _c('div',{key:`select-${select.id}`,staticClass:"select",on:{"click":function($event){_vm.show_education_status = select.id}}},[_c('img',{staticClass:"m-r-4",attrs:{"src":require(`@/assets/images/icons/${
                            _vm.show_education_status === select.id ? 'radio_on' : 'radio_off'
                        }.png`),"width":"24px","height":"24px"}}),_c('span',{domProps:{"innerHTML":_vm._s(select.key)}})])}),0)],1),(_vm.selectedEduStatus && _vm.selectedEduStatus.id > 2)?_c('div',{staticClass:"section"},[_c('div',{staticClass:"section-title",domProps:{"innerHTML":_vm._s(`출신 대학교`)}}),_c('div',{staticClass:"search-box-wrapper",on:{"click":_vm.onClickUniv}},[(_vm.university)?_c('div',{staticClass:"search-box",domProps:{"innerHTML":_vm._s(_vm.university.name)}}):_c('div',{staticClass:"search-box"},[_c('i',{staticClass:"material-icons"},[_vm._v("search")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$translate(`PLACEHOLDER_SEARCH_EDUCATION_UNIVERSITY`))}})])])]):_vm._e(),_c('div',{staticClass:"section"},[_c('div',{staticClass:"section-title required",domProps:{"innerHTML":_vm._s(`직업`)}}),_c('TextareaWithX',{attrs:{"placeholder":"임원, 주부 등","isInputMode":true},model:{value:(_vm.jobName),callback:function ($$v) {_vm.jobName=$$v},expression:"jobName"}}),_c('div',{staticClass:"section-title required"},[_vm._v("정보공개 방법")]),_c('div',{staticClass:"selector-container"},_vm._l((_vm.selectors),function(select){return _c('div',{key:`select-${select.id}`,staticClass:"select",on:{"click":function($event){_vm.show_job_name = select.id}}},[_c('img',{staticClass:"m-r-4",attrs:{"src":require(`@/assets/images/icons/${
                            _vm.show_job_name === select.id ? 'radio_on' : 'radio_off'
                        }.png`),"width":"24px","height":"24px"}}),_c('span',{domProps:{"innerHTML":_vm._s(select.key)}})])}),0)],1),_c('div',{staticClass:"section"},[_c('div',{staticClass:"section-title",domProps:{"innerHTML":_vm._s(`직장명`)}}),_c('TextareaWithX',{attrs:{"placeholder":"직장명 입력","isInputMode":true},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}}),_c('div',{staticClass:"section-title required"},[_vm._v("정보공개 방법")]),_c('div',{staticClass:"selector-container"},_vm._l((_vm.selectors),function(select){return _c('div',{key:`select-${select.id}`,staticClass:"select",on:{"click":function($event){_vm.show_company_name = select.id}}},[_c('img',{staticClass:"m-r-4",attrs:{"src":require(`@/assets/images/icons/${
                            _vm.show_company_name === select.id ? 'radio_on' : 'radio_off'
                        }.png`),"width":"24px","height":"24px"}}),_c('span',{domProps:{"innerHTML":_vm._s(select.key)}})])}),0)],1),_c('div',{staticClass:"section current",on:{"click":function($event){_vm.jobStatus = !_vm.jobStatus}}},[_c('Toggler',{staticClass:"m-r-10",model:{value:(_vm.jobStatus),callback:function ($$v) {_vm.jobStatus=$$v},expression:"jobStatus"}}),_c('div',{staticClass:"section-title"},[_vm._v("현재 재직중")])],1),_c('BottomButton',{attrs:{"disabled":_vm.disabled,"label":_vm.$translate('SAVE')},on:{"click":_vm.submit}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }